const t = {
  'en-US': {
    label: 'Coming soon',
    title: 'Earn rewards as your financial life thrives',
    description:
      "With LOOP, our forthcoming rewards program, you will earn more points the more you use your account.",
    benefitsTitle: "Get rewarded for your passion",
    ctaButton: 'Sign me up!',
    headerTitle: 'LOOP',
    bottomsheetTitle: 'Your interest has been registered.',
    bottomsheetDescription: 'When the program is available you will be one of the first to know.',
    bottomsheetButton: 'Got it'
  },
  'pt-BR': {
    label: 'Em breve',
    title: 'Ganhe recompensas à medida que sua vida financeira prospera',
    description:
      'Com o Inter Loop, nosso próximo programa de recompensas, você ganhará pontos quanto mais usar sua conta.',
    benefitsTitle: 'Sua torcida recompensada',
    ctaButton: 'Tenho interesse em participar',
    headerTitle: 'Inter Loop',
    bottomsheetTitle: 'Seu interesse foi registrado.',
    bottomsheetDescription: 'Quando o programa estiver disponível, você será um dos primeiros a saber.',
    bottomsheetButton: 'Entendi'
  },
  'es-ES': {
    label: '',
    title: '',
    description: '',
    benefitsTitle: '',
    ctaButton: '',
    headerTitle: '',
    bottomsheetTitle: '',
    bottomsheetDescription: '',
    bottomsheetButton: ''
  },
}

export default t
