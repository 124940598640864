import React, {useState, useMemo, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import InterWebviewBridge, { interWbNavigate, interWbSession } from '@interco/inter-webview-bridge'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'

import t from './i18n'
import './Loop.css'
import { initTheme, Theme } from '@interco/inter-ui'

const App = () => {
  const [lang, setLang] = useState<'pt-BR' | 'en-US' | 'es-ES'>()
  const [isBottomsheetActive, setIsBottomsheetActive] = useState(false)
  const isNativeEnv = useMemo(() => ['ANDROID', 'IOS'].includes(InterWebviewBridge.getInstance().getEnvironment()), [])
  
  useEffect(() => {
    const effect = async () => {
      try {
        initTheme(Theme.PF)
        const appInfo = await interWbSession.getAppInfo()
        setLang(appInfo.language)
        interWbSession.requestAnalytics('FIDELIDADE_ACESSO', {
          screen: 'LP Loop Coming Soon',
          content_action: 'Dado carregado',
          product: 'Loop'
        })
      } catch (error) {
        console.log(error)
        setLang('en-US')
      }
    }
    effect()
  }, [])

  const signMeUp = async () => {
    isNativeEnv && 
      interWbSession.requestAnalytics('FIDELIDADE_FLUXO', {
      screen: 'LP Loop Coming Soon',
      ref_type: 'botao',
      content_action: 'toque',
      action_id: 'Sign me up!',
      product: 'Loop'
    })
    setIsBottomsheetActive(true)
  }

  const goBack = () => {
    isNativeEnv && interWbNavigate.requestGoBack()
  }

  return lang ? (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="loop-us">
        <div className="main">
          <div className="header">
            <img
              className="header-primary-action"
              src="/arrow-left.svg"
              alt=""
              onClick={interWbNavigate.requestGoBack}
            />
            <Text variant="headline-h3" semiBold>{t[lang]?.headerTitle}</Text>
            <div className="header-secondary-action" />
          </div>
          <img className="hero" src="/banner-loopus.png" alt="" />
          <div className="container">
            <span className="label">{t[lang]?.label}</span>
            <span className="title">{t[lang]?.title}</span>
            <span className="description">{t[lang]?.description}</span>
          </div>
          <Button className="cta-button" fullWidth onClick={signMeUp}>
            {t[lang]?.ctaButton}
          </Button>
        </div>
        {isBottomsheetActive && 
          <BottomSheet onClose={goBack}>
            <div className="bottomsheet-container">
            <Text variant="headline-h3" semiBold> 
              {t[lang]?.bottomsheetTitle}
            </Text>
            <Text variant="body-3"> 
              {t[lang]?.bottomsheetDescription}
            </Text>
            <Button className='margin-top-btn' onClick={goBack}>
              {t[lang]?.bottomsheetButton}
            </Button>
            </div>
          </BottomSheet>
        }
      </div>
    </>
  ) : null
}

export default App
